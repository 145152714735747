// Styles
// @import './assets/fonts/Lato/stylesheet.css';
@import url('https://fonts.googleapis.com/css?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

// CoreUI Icons Set
@import '~@coreui/icons/css/all.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// @import '~font-awesome/css/font-awesome.min.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

@import '~leaflet/dist/leaflet.css';

@import '~bs-stepper/dist/css/bs-stepper.css';
@import '~@progress/kendo-theme-bootstrap/dist/all.css';
@import '~react-datepicker/dist/react-datepicker.css';
